import * as i from 'types';
import React from 'react';
import { graphql } from 'gatsby';

import { Template } from 'common/layout';
import { ContentArea } from 'common/contentarea';

const GeneralPage: React.FC<GeneralPageProps> = ({ data }) => {
  const layoutData = data?.contentfulPageGeneral;
  const body = layoutData?.body as i.ContentAreaBlocksTypes[] || [];
  const meta = {
    title: layoutData?.title,
    description: layoutData?.metaDescription,
    pathname: layoutData?.slug,
    locale: data.locales.edges?.[0].node.language,
  };

  return (
    <Template meta={meta}>
      <ContentArea contentTypes={body} />
    </Template>
  );
};

export default GeneralPage;

export const query = graphql`
  query GeneralPage($id: String, $language: String) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
    contentfulPageGeneral(id: {eq: $id}, node_locale: {eq: $language}) {
      title
      metaDescription
      slug
      body {
        __typename
        ...PageBodyFragment
      }
    }
  }
`;

export const pageBodyFragment = graphql`
  fragment PageBodyFragment on Node {
    ...AccordionFragment
    ...BannerFragment
    ...CarouselFragment
    ...CtaButtonFragment
    ...FilteredScheduleFragment
    ...FormBlockFragment
    ...ImageBlockFragment
    ...InstagramGridFragment
    ...ReviewCarouselFragment
    ...PricingBlockFragment
    ...HeroHeaderFullHeightFragment
    ...HeroHeaderHalfHeightFragment
    ...HeroHeaderVideoModalFragment
    ...HighlightImageLinksFragment
    ...HolyFriendsFragment
    ...InstagramGridFragment
    ...SpotifyEmbedFragment
    ...TextBlockFragment
    ...TextColumnsFragment
    ...UspBlockFragment
    ...VideoblockFragment
  }
`;

type GeneralPageProps = {
  data: GatsbyTypes.GeneralPageQuery;
};
